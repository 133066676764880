import clsx from 'clsx'
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {cn} from 'src/app/utils/cn-utils'
import {Card, GAlert, GButton} from '../../Libs'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import {AsideMenu} from './AsideMenu'
import {checkJson} from 'src/app/utils/cek-json-utils'
import {useDispatch} from 'react-redux'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import BasicModal from '../../Widgets/ModalCollection/BasicModal'
import {Refresh} from 'react-swm-icon-pack'
import {handleResponse} from 'src/setup/hooks/socketInterceptor'
import {getAuthState} from 'src/setup/hooks/getAuthState'

const baseUrl = process.env.REACT_APP_API_URL

const AsideDefault: FC = () => {
  const {minimize, setMinimize} = useMasterLayout()
  const [isHovering, setIsHovering] = useState(false)
  const [progressModal, setProgressModal] = useState(false)
  const [progressFailed, setProgressFailed] = useState(false)
  const [progress, setProgress] = useState(0)
  const [syncType, setSyncType] = useState('')
  const onMouseEnter = () => setIsHovering(true)
  const onMouseLeave = () => setIsHovering(false)
  const stationList = [
    'REQUEST_BRANCH',
    'REQUEST_CANCEL_REASON',
    'REQUEST_MEMBER',
    'REQUEST_MENU',
    'REQUEST_MENU_CATEGORY',
    'REQUEST_MENU_EXTRA',
    'REQUEST_MENU_ICON',
    'REQUEST_MENU_PACKAGE',
    'REQUEST_MENU_TEMPLATE',
    'REQUEST_NOTES_CATEGORY',
    'REQUEST_PAYMENT_METHOD',
    'REQUEST_POS_USER',
    'REQUEST_POS_USER_ROLE',
    'REQUEST_PROMOTION',
    'REQUEST_SALES_MODE',
    'REQUEST_STATION',
    'REQUEST_TABLE_MANAGEMENT',
    'REQUEST_TABLE_SECTION',
    'REQUEST_VISITOR_TYPE',
    'REQUEST_VOUCHER',
  ]
  const {token} = getAuthState()

  const syncData = () => {
    const wsSync = new WebSocket(`${baseUrl}/sync_master?token=${token}`)
    const payloadSync = {
      type: 'POST',
      data: {
        request_list: null
      },
    }

    wsSync.onopen = () => {
      wsSync.send(JSON.stringify(payloadSync))
    }
    wsSync.onerror = (error) => console.error('WebSocket Error:', error)

    wsSync.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        generateProgress()
        setProgressModal(true)
      }
      if (response?.response_schema?.response_code === '401') {
        handleResponse(event)
      }
    }
  }
  const dispatch = useDispatch()
  const getData = () => {
    const payload = {
      type: 'GET',
    }
    const ws = new WebSocket(`${baseUrl}/branch`)
    ws.onopen = () => {
      ws.send(JSON.stringify(payload))
    }
    ws.onerror = (error) => console.error('WebSocket Error:', error)

    ws.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        dispatch(AuthRedux.actions.setBranch(response.response_output.detail))
        setProgressModal(false)
        window.location.reload()
      }
    }
  }

  const generateProgress = () => {
    const ws = new WebSocket(`${baseUrl}/progress_sync_master?token=${token}`)

    ws.onerror = (error) => console.error('WebSocket Error:', error)

    ws.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      const detail = response?.response_output?.detail
      if (response.response_schema?.response_code === '200') {
        setProgress(detail?.progress)
        setSyncType(String(detail?.sync_type).replaceAll('_', ' '))
        setProgressFailed(false)
        if (detail?.progress === 100 && detail?.sync_type === null) {
          getData()
          ws.close()
        }
      }
      if (response.response_schema?.response_code === '400') {
        setProgressFailed(true)
      }
      if (response.response_schema?.response_code === '401') {
        handleResponse(event)
      }
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/backgrounds/BgMakanSideBar.png')})`,
        backgroundPosition: 'calc(100% + 100px) bottom',
        backgroundRepeat: 'no-repeat',
      }}
      className={cn(
        'bg-darkMode  bg-no-repeat h-screen bg-bottom fixed top-0 left-0 bottom-0 z-[110] transition-all duration-300 group flex flex-col -translate-x-full',
        'lg:translate-x-0',
        'dark:bg-inverse-primary-light-1',
        {'lg:w-[72px] lg:hover:w-[264px]': minimize},
        {'translate-x-0 w-[264px]': !minimize}
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* LOGO  */}
      <div className='relative flex items-center justify-center'>
        <div className='flex justify-center items-center h-[80px] px-6 text-fs-5 font-semibold text-white'>
          <Link to='/table-list' className='relative flex items-center justify-center'>
            {minimize ? (
              <>
                {isHovering ? (
                  <img
                    src={toAbsoluteUrl('/media/logos/LogoRamen.svg')}
                    alt='Product'
                    className=' h-10'
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl('/media/logos/LogoRamen.svg')}
                    alt='Product'
                    className='w-8 h-8'
                  />
                )}
              </>
            ) : (
              <div className='flex items-center justify-center gap-x-2'>
                <img
                  src={toAbsoluteUrl('/media/logos/LogoRamen.svg')}
                  alt='Product'
                  className='h-6 w-[44.65px]'
                />
                <span className=' text-[#D0D5DD] text-2xl font-bold'>CORE</span>
              </div>
            )}
          </Link>
        </div>

        {/* TOGGLER */}
        <Card.Root
          data-testid='toggle-button'
          onClick={() => setMinimize(!minimize)}
          className={cn(
            'cursor-pointer z-1 absolute border-0 w-9 h-9 rounded top-50 right-0 translate-x-[50%] items-center justify-center',
            'lg:flex',
            {hidden: minimize},
            {flex: !minimize}
          )}
        >
          <KTSVG
            path={'/media/icons/IconDoubleChevronLeft.svg'}
            className={clsx('h-24px w-24px transition-all duration-300', {
              'rotate-180': minimize,
            })}
          />
        </Card.Root>
      </div>

      {/* begin::Aside menu */}
      <div className='flex flex-col flex-1 w-auto h-full p-0 m-0'>
        <AsideMenu />
        <div className='m-2 ms-2'>
          <GButton
            id='sync-button'
            className={clsx(
              'flex flex-row justify-center text-[#0B6417] p-2 border border-[#0B6417] items-center rounded-lg w-full h-[44px] bg-[#EFFCE3]'
            )}
            variant='UNSTYLED'
            data-testid='refresh-button'
            size='small'
            onClick={() => syncData()}
          >
            Sync
          </GButton>
        </div>
      </div>
      {/* end::Aside menu */}

      <BasicModal
        show={progressModal}
        size='xl'
        handleClose={() => (progressFailed ? setProgressModal(false) : null)}
        header='Syncronize Data'
        isBtnCloseHidden={!progressFailed}
        hideFooter={true}
        isProgressBar={true}
      >
        <div className='flex flex-col gap-4 w-94 h-204 rounded-lg'>
          {progressFailed && (
            <GAlert
              className='px-4 py-3 mb-12'
              colors='warning'
              iconClassname='text-neutral-100'
              textClassname='text-neutral-100 text-fs-8'
            >
              Progress Sync failed!
            </GAlert>
          )}
          <div className='flex justify-between' id='sync-in-progress'>
            <span>Automatic Synchronization on process</span>
            <button>
              <Refresh />
            </button>
          </div>

          <progress
            className='rounded'
            value={progress}
            max={100}
            style={{height: '10px', borderRadius: '5px', width: '100%'}}
          />

          <div>
            <span className='text-[#667085]'>
              Fetch {syncType}: {progress}%
            </span>
          </div>
        </div>
      </BasicModal>
    </div>
  )
}

export default AsideDefault
