import {FC, MouseEventHandler, ReactNode, useEffect} from 'react'
import {GModal} from '../../Libs'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'
import clsx from 'clsx'

interface BasicModalProps {
  show: boolean
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  handleClear?: MouseEventHandler
  handleOther?: MouseEventHandler
  header: string
  children?: ReactNode
  otherLabel?: string
  clearLabel?: string
  positiveLabel?: string
  negativeLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  isBtnCloseHidden?: boolean
  subHeader?: string
  hideFooter?: boolean
  fullChildren?: boolean
  isProgressBar?: boolean
  disabledButton?: boolean
  showHeader?: boolean
  useEscape?: boolean
  staticClose?: boolean
}

const BasicModal: FC<BasicModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  handleClear,
  handleOther,
  header,
  positiveLabel,
  negativeLabel,
  otherLabel,
  clearLabel,
  size = 'md',
  loading,
  children,
  isBtnCloseHidden,
  subHeader,
  hideFooter = false,
  fullChildren = false,
  isProgressBar = false,
  disabledButton = false,
  showHeader = true,
  useEscape = true,
  staticClose = false,
}) => {
  return (
    <GModal show={show} onHide={staticClose ? undefined: handleClose} size={size} useEscape={useEscape}>
      {showHeader && header && (
        <ModalHeader
          header={header}
          handleClose={handleClose}
          isBtnCloseHidden={isBtnCloseHidden}
          subHeader={subHeader}
        />
      )}
      <div className={clsx('min-h-[100px] max-h-[450px] text-neutral-80 w-full overflow-x-auto', {'px-6 py-5': !fullChildren})}>
        {children}
      </div>
      {!hideFooter && (
        <ModalFooter
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          handleClear={handleClear}
          handleOther={handleOther}
          positiveLabel={positiveLabel}
          negativeLabel={negativeLabel}
          clearLabel={clearLabel}
          otherLabel={otherLabel}
          loading={loading}
          disabled={disabledButton}
        />
      )}
      {isProgressBar && (
        <div className='p-5'>
          <span className='text-sm text-[#98A2B3] '>
            Please wait. Once synchronization process is complete, you can still make a transaction
          </span>
        </div>
      )}
    </GModal>
  )
}

export default BasicModal
