import {useCallback, useEffect, useState} from 'react'

export const useModal = (show: boolean, useEscape: boolean) => {
  const [animateClose, setAnimateClose] = useState<boolean>(false)
  const [temporaryShow, setTemporaryShow] = useState<boolean>(false)

  const handleAnimateClose = useCallback((e?: any) => {
    setAnimateClose(true)
    setTimeout(() => {
      setAnimateClose(false)
      setTemporaryShow(false)
    }, 300)
  }, [])

  useEffect(() => {
    if(useEscape) {
      const closeOnEscapeKey = (e: any) => (e.key === 'Escape' ? handleAnimateClose() : null)
      document.body.addEventListener('keydown', closeOnEscapeKey)
      return () => document.body.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [handleAnimateClose])

  useEffect(() => {
    if (!show) return handleAnimateClose()
    else return setTemporaryShow(true)
    //eslint-disable-next-line
  }, [show])

  return {animateClose, temporaryShow}
}
