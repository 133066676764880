import {getAuthState} from '../hooks/getAuthState'

// const baseUrl = process.env.REACT_APP_API_URL
const baseUrl = getWebSocketBaseUrl()
// const baseUrl = `ws://localhost:9002`


export default function setupWebsocket(url: string, payload: any) {
  
  const {token} = getAuthState()
  const skipTokenSocket = ['login']
  const ws = new WebSocket(
    skipTokenSocket.includes(url) ? `${baseUrl}/${url}` : `${baseUrl}/${url}?token=${token ?? ''}`
  )
  ws.onopen = () => {
    ws.send(JSON.stringify(payload))
  }

  ws.onerror = (error) => {
    console.error('WebSocket Error:', error)
  }

  ws.onmessage = (event) => {
    return event
  }

  ws.onclose = (event) => {
    console.log('WebSocket Close:', url, event)
  }

  return ws
}

export function getWebSocketBaseUrl(): string {
  const url = window.location.href; 
  const hostname = new URL(url).hostname;

  const isIpAddress = /^(\d{1,3}\.){3}\d{1,3}$/.test(hostname);

  const port = 9002; 
  const protocol = "ws"; 

  if (isIpAddress) {
    return `${protocol}://${hostname}:${port}`; 
  } else {
    return `${protocol}://localhost:${port}`; 
  }
}