import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  handleClear?: MouseEventHandler
  handleOther?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  clearLabel?: string
  otherLabel?: string
  loading?: boolean
  disabled?: boolean
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  handleClear,
  handleOther,
  positiveLabel,
  negativeLabel,
  clearLabel,
  otherLabel,
  loading,
  disabled,
}) => {
  return (
    <div className='flex bg-gray-50 gap-x-3 justify-between items-center w-full px-6 py-5 border-t border-neutral-30 sticky bottom-0 bg-white rounded-b-lg'>
      {handleClear ?
        <GButton
          onClick={handleClear}
          type='button'
          variant='OUTLINED'
          className='min-w-[138px] text-sm font-medium'
          disabled={loading}
          data-testid='clear-button'
        >
          {clearLabel ?? 'Clear'}
        </GButton>:
        <>
          <button disabled></button>
        </>
      }
      <div className='flex justify-start gap-3'>
      {handleOther ? 
        
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='min-w-[138px] text-sm font-medium border border-[#D0D5DD] text-[#344054]'
          disabled={loading}
          data-testid='close-button'
        >
          {negativeLabel ?? 'Next'}
        </GButton>:
          <>
            <button disabled></button>
          </>
        }
        {handleClose ? 
        
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='min-w-[138px] text-sm font-medium border border-[#D0D5DD] text-[#344054]'
          disabled={loading}
          data-testid='close-button'
        >
          {negativeLabel ?? 'No'}
        </GButton>:
          <>
            <button disabled></button>
          </>
        }

        {handleSuccess ? 
        <GButton
          onClick={handleSuccess}
          className='min-w-[138px] text-sm font-medium'
          type='button'
          loading={loading}
          disabled={disabled}
          data-testid='success-button'
        >
          {positiveLabel ?? 'Yes'}
        </GButton>:
        <>
          <button disabled></button>
        </>
        
        }

      </div>
    </div>
  )
}

export default ModalFooter
