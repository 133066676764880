/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {IUser} from 'src/app/models/api.types'
import ListSalesMode from 'src/app/modules/order/Components/ListSalesMode.component'
import ListVisitorComponent from 'src/app/modules/order/Components/ListVisitor.components'
import OrderRedux from 'src/app/modules/order/redux/OrderRedux'
import {checkJson} from 'src/app/utils/cek-json-utils'
import {cn} from 'src/app/utils/cn-utils'
import {RootState} from 'src/setup'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import NumberPad from '../../Libs/NumberPad/NumberPad.component'
import OrderModal from '../../Widgets/ModalCollection/OrderModal'
import {IMergeTableInfo, ITable, IVisitor, TableLayout} from './models/Table.model'
import {handleResponse} from 'src/setup/hooks/socketInterceptor'
import GIcon from '../../Libs/Icon/GIcon'
import ModalConfirm from '../../Widgets/ModalCollection/ModalConfirm'

export enum Visitor {
  Reguler = 'Reguler',
  Entertainment = 'Entertainment',
}

export const buttonClass = (isActive: boolean) =>
  `flex-none flex-row justify-center items-center px-2 py-2 gap-2 h-11 rounded-lg border text-sm ${
    isActive ? 'bg-[#950F2C] text-white' : 'bg-white text-[#CF1E26] border-[#CF1E26]'
  }`
const TableStyle: React.FC<any> = ({
  onClick,
  dataBooked = '',
  name = '',
  type = '',
  position = '',
  mergeTableData = [],
  isOpen = false,
  status = null,
  index
}) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
    radius: '',
  })
  const handleClick = (value: any) => {
    onClick(value)
  }

  const [lastTime, setLastTime] = useState('')

  const handleSize = (type: string) => {
    switch (type) {
      case 'SQUARE':
        setSize({width: 120, height: 120, radius: ''})
        break

      case 'RECTANGLE':
        setSize({width: 200, height: 120, radius: ''})
        break

      case 'CIRCLE':
        setSize({width: 160, height: 160, radius: '50%'})
        break

      case 'SMALL_SQUARE':
        setSize({width: 60, height: 60, radius: ''})
        break

      case 'SMALL_RECTANGLE':
        setSize({width: 120, height: 60, radius: ''})
        break

      case 'SMALL_CIRCLE':
        setSize({width: 80, height: 80, radius: '50%'})
        break

      default:
        break
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (dataBooked?.book_date) setLastTime(dateTime(dataBooked?.book_date))
    }, 1000)
    return () => clearInterval(interval)
  }, [dataBooked?.book_date])

  const dateTime = (lastUpdate: string) => {
    const currentDate = new Date()
    const lastChange = new Date(lastUpdate)
    const datetimeDiff = currentDate.getTime() - lastChange.getTime()
    const hours = Math.floor(datetimeDiff / (60 * 60 * 1000))
    const mins = Math.floor((datetimeDiff - hours * 60 * 60 * 1000) / (60 * 1000))
    return `${hours < 10 ? '0' + hours : hours}:${mins < 10 ? '0' + mins : mins}`
  }

  const tableColor = (data: any) => {
    switch (data.status?.toLowerCase()) {
      case 'available':
        return 'bg-[#0F4F9E]'

      case 'booked':
        let bg = 'bg-[#EEC860]'
        return bg
      case 'occupied':
        return 'bg-[#CF1E26]'

      case 'billed':
        return 'bg-[#53BA45]'
      default:
        return 'bg-[#0F4F9E]'
    }
  }

  const fontColor = (data: any) => {
    let timerStyle = 'bg-[#F9E296] rounded-lg text-[#475467]'
    const currentDate = new Date()
    const lastChange = new Date(data?.book_date)
    const datetimeDiff = currentDate.getTime() - lastChange.getTime()
    const hours = Math.floor(datetimeDiff / (60 * 60 * 1000))
    const mins = Math.floor((datetimeDiff - hours * 60 * 60 * 1000) / (60 * 1000))
    if (hours > 0 || mins > 10) timerStyle = 'bg-[#F5B79E] text-[#475467] border-b rounded-lg'

    if (data.status?.toLowerCase() === 'available') timerStyle = ''

    return timerStyle
  }

  useEffect(() => {
    handleSize(type)
  }, [type])

  const generateTableInfo = (tableName: string) => {
    let dataTable = ''
    if (mergeTableData.length > 0) {
      const primary: IMergeTableInfo = mergeTableData?.find(
        (e: IMergeTableInfo) => e.is_primary_merge
      )
      dataTable =
        primary?.name === tableName ? (status === 'LINK' ? '(P)' : '') : `(${primary?.name})`
    }
    return dataTable
  }

  return (
    <div
      style={{
        width: size.width,
        height: size.height,
        borderRadius: size.radius,
        left: `${position.x || 0}px`,
        top: `${position.y || 0}px`,
      }}
      className={cn(
        'absolute text-center text-white text-[12px] flex flex-row justify-center items-center gap-2 p-3 cursor-pointer',
        tableColor(dataBooked)
      )}
      onClick={() => handleClick(name)}
    >
      {isOpen && <GIcon icon='IconAlertYellow' className='absolute -top-5 -right-5' />}
      <div className='flex flex-col'>
        <span data-cy={`table-${index}`}>{`${name} ${generateTableInfo(name)}`}</span>
        {dataBooked?.book_date && (
          <span className={clsx('p-1', fontColor(dataBooked))}>
            {dataBooked?.book_date ? lastTime : ''}
          </span>
        )}
      </div>
    </div>
  )
}

const TableComponent: React.FC<{dataBranch: any; selecedSection: any}> = ({
  dataBranch = '',
  selecedSection,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [numberPax, setNumberPax] = useState('')
  const [selectedTable, setSelectedTable] = useState<any>(null)
  const [visitorType, setVisitorType] = useState<IVisitor | undefined>()
  const [tableData, setTableData] = useState<ITable>()
  const history = useHistory()
  const user: IUser = useSelector<RootState>(
    ({auth}) => auth?.user,
    shallowEqual
  ) as unknown as IUser
  const token = user?.token_auth
  const [listVisitor, setListVisitor] = useState<IVisitor[] | null>([])
  const [salesMode, setSalesMode] = useState<any>()
  const [tableIsOpened, setTableIsOpened] = useState<string>('')

  useEffect(() => {
    if (dataBranch && !tableData) {
      getDataTable(selecedSection?.id)
    }
  }, [dataBranch, tableData])

  useEffect(() => {
    getDataTable(selecedSection?.id)
  }, [selecedSection, history])

  useEffect(() => {
    getDataTable(selecedSection?.id)
  }, [])

  const getDataTable = (id: number) => {
    const payload = {
      type: 'POST',
      token: token ? token : '',
      data: {
        section_id: id,
        is_table_order: true,
      },
    }
    const ws: WebSocket = setupWebsocket('table', payload)

    ws.onmessage = (event: any) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        setTableData(response.response_output.detail)
      } else {
        handleResponse(event)
      }
    }
  }

  const inputNumber = (number: number) => {
    const input = `${numberPax}${number}`
    if (input !== '0') setNumberPax(input)
  }

  const clearNumber = () => {
    setNumberPax('')
  }

  useEffect(() => {
    if (!listVisitor) {
      const payloadVisitor = {
        type: 'GET',
        token,
      }
      const wsVisitor = setupWebsocket('visitor_type', payloadVisitor)
      wsVisitor.onmessage = (event) => {
        const response = checkJson(event.data) ? JSON.parse(event.data) : ''
        if (response?.response_schema?.response_code === '200') {
          setListVisitor(response?.response_output.list.content)
        } else {
          handleResponse(event)
        }
      }
    }
  }, [])

  const selectTable = (data: any) => {
    setSelectedTable(data)

    if (data?.table_books) {
      const selectedVisitor: any = listVisitor?.find((item: any) => item?.id === visitorType?.id)

      dispatch(
        OrderRedux.actions.BookTableOnly(
          selectedVisitor,
          data?.table_books?.total_pax,
          data?.table_books?.table_management_layout_id,
          data.name,
          'Dine In'
        )
      )
      const tableStatus = tableData?.table_layout?.find(
        (table) => table.id === data?.id
      )?.table_status
      history.push('/order', {tableStatus, tableBook: data?.table_books})
    } else {
      setNumberPax('')
      setShowModal(true)
    }
  }
  const dispatch = useDispatch()

  const bookTable = (type: 'book' | 'bookorder') => {
    const bookPayload = {
      type: 'POST',
      data: {
        table_management_layout_id: selectedTable?.id,
        sales_mode_id: salesMode?.id,
        status: 'booked',
        created_by: user?.username,
        total_pax: parseInt(numberPax),
        visitor_type_id: visitorType?.id,
        is_self_order: false,
      },
    }

    setupWebsocket('table_book', bookPayload).onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        if (type === 'bookorder') {
          const selectedVisitor =
            listVisitor && listVisitor.find((item: any) => item.id === visitorType?.id)
          dispatch(
            OrderRedux.actions.BookTableOnly(
              selectedVisitor as IVisitor,
              numberPax,
              selectedTable?.id,
              selectedTable?.name,
              'Dine In',
              salesMode
            )
          )
          const tableStatus = tableData?.table_layout?.find(
            (table) => table.id === selectedTable?.id
          )?.table_status
          history.push('/order', {
            bookPayload,
            tableStatus,
            tableBook: response?.response_output?.detail,
          })
        }
        // saveOrder(id, type);
        getDataTable(selecedSection?.id)
        setShowModal(false)
      } else {
        handleResponse(event)
      }
      setVisitorType(undefined)
      setSalesMode(undefined)
    }
  }

  useEffect(() => {
    const payload = {
      type: 'POST',
      data: {
        section_id: selecedSection?.id ?? '',
        is_table_order: true,
      },
    }
    const wsTableBroadCast = setupWebsocket('table_broadcast', payload)
    wsTableBroadCast.onmessage = (event: any) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        setTableData(response.response_output.detail)
      } else {
        handleResponse(event)
      }
    }

    return () => {
      wsTableBroadCast.close()
    }
  }, [])

  return (
    <div className='flex flex-col justify-between grow h-full bg-[#F9FAFB] rounded-lg'>
      <div className='relative h-full overflow-x-auto z-30'>
        {tableData?.table_layout?.map((table: TableLayout, index: number) => {
          return (
            <TableStyle
              index={index}
              key={index}
              dataBooked={table?.table_books}
              name={table.name}
              type={table.type}
              position={table.position}
              mergeTableData={table?.merged_table_info}
              onClick={(value: string) => {
                if (table?.open_by_user === '') {
                  return user ? selectTable(table) : ''
                } else {
                  setTableIsOpened(table?.open_by_user)
                }
              }}
              isOpen={table?.open_by_user !== ''}
              status={table?.table_status}
            ></TableStyle>
          )
        })}
      </div>
      {tableData?.using_layout_image && (
        <div className='absolute z-0 w-full'>
          <img src={tableData?.layout_image_local} alt='layout' className='w-[60vh] object-fill' />
        </div>
      )}
      <div>
        <div className='flex flex-row justify-between p-5 items-center'>
          <div className='flex flex-row gap-2'>
            <p className='px-2 bg-[#F9E296] font-poppins text-[12px] font-normal leading-4 text-[#475467]'>
              {'>5 Minute'}
            </p>
            <p className='px-2 bg-[#F5B79E] font-poppins text-[12px] font-normal leading-4 text-[#475467]'>
              {'>10 Minute'}
            </p>
          </div>
          <div className='flex flex-row gap-3'>
            <div className='flex flex-row gap-1'>
              <div className='p-2 bg-[#53BA45] rounded-[4px]'></div>
              <p className='font-poppins text-[12px] font-normal leading-4 text-[#475467]'>
                {'Billed'}
              </p>
            </div>
            <div className='flex flex-row gap-1'>
              <div className='p-2 bg-[#CF1E26] rounded-[4px]'></div>
              <p className='font-poppins text-[12px] font-normal leading-4 text-[#475467]'>
                {'Occupied'}
              </p>
            </div>
            <div className='flex flex-row gap-1'>
              <div className='p-2 bg-[#EEC860] rounded-[4px]'></div>
              <p className='font-poppins text-[12px] font-normal leading-4 text-[#475467]'>
                {'Booked'}
              </p>
            </div>
            <div className='flex flex-row gap-1'>
              <div className='p-2 bg-[#0F4F9E] rounded-[4px]'></div>
              <p className='font-poppins text-[12px] font-normal leading-4 text-[#475467]'>
                {'Available'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <OrderModal
        show={showModal}
        size='sm'
        handleClose={() => {
          setShowModal(false)
          setVisitorType(undefined)
          setSalesMode(undefined)
        }}
        handleActionOne={() => bookTable('book')}
        handleActionTwo={() => bookTable('bookorder')}
        header='Book Table'
        selectedTable={selectedTable?.name}
        positiveLabel='Book & Order'
        negativeLabel='Book Table Only'
        statusOrder='DINE IN'
        disabledButton={!salesMode || !numberPax}
      >
        <div className='flex flex-col gap-4 w-94 h-204 rounded-lg'>
          <div className='flex flex-col items-start p-0 gap-7 w-full'>
            {/* Input field */}
            <div className='flex w-full flex-col p-0 gap-1 h-11 mb-3'>
              <span className='text-sm'>Number of Pax </span>
              <input
                type='text'
                className={`box-border text-center flex items-center p-3 gap-2 h-11 bg-white border border-solid border-[#D0D5DD] shadow-[0_1px_2px_rgba(16,24,40,0.05)] rounded-lg`}
                placeholder='Enter Number of Pax'
                readOnly={true}
                value={numberPax}
                max={5}
                maxLength={5}
              />
            </div>
            {/* Keypad */}
            <NumberPad
              onInput={(value) => {
                if (numberPax?.length < 6) {
                  inputNumber(value)
                }
              }}
              onClear={() => clearNumber()}
            ></NumberPad>
            {/* Submit Button */}
          </div>
          <ListVisitorComponent
            visitors={visitorType}
            onSelecetVisitor={(data: IVisitor | undefined) => setVisitorType(data)}
            listVisitor={listVisitor}
            type='DINE_IN'
          />
          <ListSalesMode
            salesMode={salesMode}
            setSalesMode={(sales) => {
              setSalesMode(sales)
            }}
            type='DINE_IN'
          />
        </div>
      </OrderModal>
      <ModalConfirm
        negativeLabel='Cancel'
        positiveLabel='Continue'
        show={tableIsOpened.length > 0}
        handleSuccess={() => {
          setTableIsOpened('')
          const tableStatus = tableData?.table_layout?.find(
            (table) => table.id === selectedTable?.id
          )?.table_status
          history.push('/order', {tableStatus, tableBook: selectedTable?.table_books})
        }}
        handleClose={() => setTableIsOpened('')}
        header={`Table is being used by ${tableIsOpened}`}
        type={'WARNING'}
      />
    </div>
  )
}

export default TableComponent
